import styled from "styled-components"

import { minWidth, maxWidth } from "utils"

import Icon from "icons"

export const Container = styled.footer`
  padding: 6rem 2rem 1rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.footer};
  color: ${({ theme }) => theme.colors.text.alternate};

  ${minWidth.mobile`
    padding-left: 4rem;
    padding-right: 4rem;
  `}
`

export const MBGLogo = styled.div`
  font-family: Monoline, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3.16rem;
  ::after {
    content: "Mindbodygreen";
  }
`

export const Links = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 1rem 2rem;
    font-family: Maax, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4rem;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;

    ${minWidth.mobile`
      font-size: 1.5rem;
      line-height: 1.5rem;
    `}
  }

  ${maxWidth.desktop`
    max-width: 144rem;
  `}
`

export const SocialLinks = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin: 0 1rem;
  }
`

export const SocialIcon = styled(Icon)`
  max-width: 4rem;
  color: #fff;
`

export const Copyright = styled.p`
  font-family: Maax, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.8rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #fff;
`

export const Disclaimer = styled.p`
  display: ${({ isHomePage }) => (isHomePage ? "block" : "none")};
  max-width: 57.5rem;
  margin: 5rem auto 0;
  padding: 2.5rem;
  border: 0.1rem solid #fff;
  font-size: 1.2rem;
  line-height: 1.75;
  letter-spacing: 0.255rem;
  text-align: center;
  color: #fff;
`

export const UtilityLinks = styled.div`
  margin: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 1rem 2.5rem;
    font-family: Maax, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 140%;
    letter-spacing: 2px;
    text-transform: lowercase;
    text-decoration: none;
    color: #fff;
  }

  ${maxWidth.desktop`
    max-width: 144rem;
  `}
`
