import React from "react"
import { Script } from "gatsby"

import useJustunoTracking from "hooks/useJustunoTracking"

import Helmet from "react-helmet"
import Footer from "../footer"
import {
  GlobalStyles,
  Container,
  TopAccessibilityContainer,
  TopAccessibilityLink,
  Separator,
} from "./styles"

const Layout = ({ children }) => {
  useJustunoTracking()

  return (
    <>
      <Helmet>
        <script src="https://www.dwin1.com/19038.js" type="text/javascript" defer="defer" />
      </Helmet>
      <Script id="refersion-tracking">
        {`
          (function (w,d,s,u,o,c,e,x) {
            w['TrackingSystemObject']=o;
            e=d.createElement(s);e.async=1;e.src=u;e.onload=c;x=d.getElementsByTagName(s)[0];
            x.parentNode.insertBefore(e, x);
          })(window,document,'script','https://cdn.refersion.com/refersion.js','r',function () {
            r.pubKey = 'pub_ff8495c9ec635f6d582b';
            r.initializeXDLS().then(() => {
              r.launchDefault();
            });
          });
        `}
      </Script>
      <GlobalStyles />
      <TopAccessibilityContainer>
        <TopAccessibilityLink href="#content" aria-label="Skip to content">
          Skip to Content
        </TopAccessibilityLink>
        <Separator />
        <TopAccessibilityLink
          href="/accessibility"
          target="_blank"
          aria-label="Accessibility Statement"
        >
          Accessibility Statement
        </TopAccessibilityLink>
      </TopAccessibilityContainer>
      <Container>
        {children}
        <Footer />
      </Container>
    </>
  )
}

export default Layout
