import React from "react"

import { useLocation } from "store/contexts/location"
import {
  Container,
  Links,
  SocialLinks,
  SocialIcon,
  Disclaimer,
  UtilityLinks,
  MBGLogo,
  Copyright,
} from "./styles"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  // Get the current location from the location context,
  // this one is temporary should be removed after the homepage will be migrated to contentful
  const { location } = useLocation()
  const isHomePage = location.pathname === "/"
  return (
    <Container id="footer">
      <a href="/" aria-label="Go to main page">
        <MBGLogo />
      </a>
      <Links>
        <a href="/about">About us</a>
        <a href="/contact">Contact</a>
        <a href="/contribute">Write for us</a>
        <a href="/jobs">Jobs</a>
        <a href="/advertise">Advertise</a>
        <a href="/affiliates">Become an Affiliate</a>
        <a href="/collective">Collective</a>
      </Links>
      <SocialLinks>
        <a href="https://www.instagram.com/mindbodygreen">
          <SocialIcon id="instagram-icon" height={40.71} title="Instagram" />
        </a>
        <a href="https://www.facebook.com/mindbodygreen">
          <SocialIcon id="facebook-icon" height={36.03} title="Facebook" />
        </a>
        <a href="https://twitter.com/mindbodygreen">
          <SocialIcon id="twitter-icon" height={28.62} title="Twitter" />
        </a>
        <a href="https://www.pinterest.com/mindbodygreen">
          <SocialIcon id="pinterest-icon" height={37.25} title="Pinterest" />
        </a>
        <a href="https://www.youtube.com/mindbodygreen">
          <SocialIcon id="youtube-icon" height={32} title="YouTube" />
        </a>
      </SocialLinks>
      <Copyright>&copy; 2009 - {currentYear} mindbodygreen LLC. All rights reserved.</Copyright>
      <Disclaimer id="footer_disclaimer" isHomePage={isHomePage}>
        * These statements have not been evaluated by the Food and Drug Administration. This product
        is not intended to diagnose, treat, cure or prevent any disease.
      </Disclaimer>
      <UtilityLinks>
        <a href="/terms-of-sale">Terms of Sale</a>
        <a href="/terms-of-use">Terms of Use</a>
        <a href="/disclaimer">Disclaimer</a>
        <a href="/privacy">Privacy</a>
        <a href="/data-collection">Data Collection</a>
        <a href="/accessibility">Accessibility</a>
        <a href="/editorial-process">Editorial Process</a>
      </UtilityLinks>
    </Container>
  )
}

export default Footer
